<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <hr>
          <h2>Liste des objectifs distributeurs</h2>
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche
                </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Exporter"
              variant="primary"
              class="ml-2 btn_export"
            >
              <b-dropdown-item @click="exportCSV">
                CSV
              </b-dropdown-item>
              <b-dropdown-item @click="exportExcel">
                MS-Excel
              </b-dropdown-item>
            </b-dropdown>
            <!-- button -->
            <b-button
              id="toggle-btn"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.objectif-modal-prevent-closing
              variant="outline-primary"
              class="btn_export ml-2"
              @click="resetModal()"
            >
              <feather-icon icon="PlusCircleIcon" />
              Ajouter un objectif
            </b-button>

            <!-- modal -->
            <b-modal
              id="objectif-modal-prevent-closing"
              ref="objectif-modal"
              title="Nouvel objectif"
              ok-title="Enregistrer"
              cancel-variant="outline-secondary"
              cancel-title="Annuler"
              size="lg"
              @hidden="resetModal"
              @ok="handleOk"
            >
              <form
                ref="form"
                @submit.stop.prevent="handleSubmit"
              >
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :state="objectif.moisState"
                      label="Mois"
                      label-for="type-input"
                      invalid-feedback="Le mois est requis"
                    >
                      <v-select
                        v-model="objectif.mois"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="[
                          'Janvier',
                          'Février',
                          'Mars',
                          'Avril',
                          'Mai',
                          'Juin',
                          'Juillet',
                          'Août',
                          'Septembre',
                          'Octobre',
                          'Novembre',
                          'Décembre'
                        ]"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6" />
                  <b-col md="6">
                    <b-form-group
                      :state="objectif.commercialState"
                      label="Commercial"
                      label-for="type-input"
                      invalid-feedback="Le commercial est requis"
                    >

                      <v-select
                        v-model="objectif.commercial"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="full_name"
                        :options="commerciaux"
                        @input="fetchDistributeurs"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :state="objectif.distributeurState"
                      label="Distributeur"
                      label-for="canal-input"
                      invalid-feedback="Veuillez choisir un distributeur"
                    >

                      <v-select
                        id="canal-input"
                        v-model="objectif.distributeur"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="nom"
                        :options="distributeurs"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                  >

                    <b-form-group
                      :state="objectif.objectif_stock_sortie_1lState"
                      label="Objectif 1L"
                      label-for="objectif-1l"
                      invalid-feedback="L'objectif est requis"
                    >

                      <b-form-input
                        id="objectif-1l"
                        v-model="objectif.objectif_stock_sortie_1l"
                        :state="objectif.objectif_stock_sortie_1lState"
                        required
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                  >

                    <b-form-group
                      :state="objectif.objectif_stock_sortie_50clState"
                      label="Objectif 50cL"
                      label-for="objectif-5c0l"
                      invalid-feedback="L'objectif est requis"
                    >

                      <b-form-input
                        id="objectif-1l"
                        v-model="objectif.objectif_stock_sortie_50cl"
                        :state="objectif.objectif_stock_sortie_50clState"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </form>
              <br><br><br><br><br><br><br><br>
            </b-modal>
          </div>
          <vue-good-table
            id="list"
            ref="myTable"
            :columns="columns"
            :rows="objectifs"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'taux_realisation'">
                <b-badge
                  v-if="props.row.taux_realisation>=70"
                  variant="light-success"
                >
                  {{ props.row.taux_realisation }} %
                </b-badge>
                <b-badge
                  v-if="props.row.taux_realisation<70 && props.row.taux_realisation>=50"
                  variant="light-warning"
                >
                  {{ props.row.taux_realisation }} %
                </b-badge>
                <b-badge
                  v-if="props.row.taux_realisation<50"
                  variant="light-danger"
                >
                  {{ props.row.taux_realisation }} %
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <b-button-group>
                  <!--                  <b-button-->
                  <!--                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
                  <!--                    variant="outline-primary"-->
                  <!--                    :to="{ name: 'distributeurs-visite-details', params: { id: props.row.id } }"-->
                  <!--                    target="_blank"-->
                  <!--                  >-->
                  <!--                    Voir-->
                  <!--                  </b-button>-->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="handleUpdate(props.row)"
                  >
                    Modifier
                  </b-button>
                  <b-button
                    v-if="props.row.realisation_stock_sortie_1l==0 && props.row.realisation_stock_sortie_50cl==0"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="handleDelete(props.row.id)"
                  >
                    Supprimer
                  </b-button>
                </b-button-group>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage de 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> sur {{ props.total }} élements </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card>
          <form
            ref="form"
            @submit.stop.prevent="handleSubmit"
          >
            <b-row>
              <b-col md="6">
                <b-form-group
                  :state="objectif.moisState"
                  label="Mois"
                  label-for="type-input"
                  invalid-feedback="Le mois est requis"
                >
                  <v-select
                    v-model="month"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="[
                      'Janvier',
                      'Février',
                      'Mars',
                      'Avril',
                      'Mai',
                      'Juin',
                      'Juillet',
                      'Août',
                      'Septembre',
                      'Octobre',
                      'Novembre',
                      'Décembre'
                    ]"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <div style="text-align: center">
                  <b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.distributeur-modal-prevent-closing
                    variant="outline-primary"
                    class="btn_export mt-2"
                    @click="getObjectsMensuelDetails"
                  >
                    <feather-icon icon="SearchIcon" />
                    Filtrer
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </form>
          <hr>
          <b-row>
            <b-col md="6">
              <h2>SUVI VENTES</h2>
              <!-- input search -->
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Recherche
                    </label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Recherche"
                      type="text"
                      class="d-inline-block"
                    />
                  </div>
                </b-form-group>
                <!-- primary -->
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  text="Exporter"
                  variant="primary"
                  class="ml-2 btn_export"
                >
                  <b-dropdown-item @click="exportCSV">
                    CSV
                  </b-dropdown-item>
                  <b-dropdown-item @click="exportExcel">
                    MS-Excel
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <vue-good-table
                id="list"
                ref="myTable"
                :columns="columns2"
                :rows="objectifsDetails"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Status -->
                  <span v-if="props.column.field === 'taux_realisation'">
                    <b-badge
                      v-if="props.row.taux_realisation>=70"
                      variant="light-success"
                    >
                      {{ props.row.taux_realisation }} %
                    </b-badge>
                    <b-badge
                      v-if="props.row.taux_realisation<70 && props.row.taux_realisation>=50"
                      variant="light-warning"
                    >
                      {{ props.row.taux_realisation }} %
                    </b-badge>
                    <b-badge
                      v-if="props.row.taux_realisation<50"
                      variant="light-danger"
                    >
                      {{ props.row.taux_realisation }} %
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'mois'">
                    <b-badge
                      variant="light-success"
                    >
                      {{ month }}
                    </b-badge>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        Affichage de 1 à
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> sur {{ props.total }} élements </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
            <b-col md="6">
              <SimpleColumnChart :chart-data="objectifsDetails" v-if="objectifsDetails" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton, BButtonGroup,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'
import vSelect from 'vue-select'
import SimpleColumnChart from '@/views/m-components/charts/SimpleColumnChart.vue'

export default {
  name: 'VisiteListeMainVue',
  components: {
    SimpleColumnChart,
    vSelect,
    BButtonGroup,
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    tourner: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rows: [],
      objectifs: [
      ],
      types: [
      ],
      tourneData: {
      },
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Date',
          field: 'date_created',
        },
        {
          label: 'Mois',
          field: 'mois',
        },
        {
          label: 'Commercial',
          field: 'commercial.full_name',
        },
        {
          label: 'Distributeur',
          field: 'distributeur.nom',
        },
        {
          label: 'Objectif 1L',
          field: 'objectif_stock_sortie_1l',
        },
        {
          label: 'Objectif 50CL',
          field: 'objectif_stock_sortie_50cl',
        },
        {
          label: 'Total Objectif',
          field: 'total_objectif',
        },
        {
          label: 'Realisation 1L',
          field: 'realisation_stock_sortie_1l',
        },
        {
          label: 'Realisation 50CL',
          field: 'realisation_stock_sortie_50cl',
        },
        {
          label: 'Total Realisation',
          field: 'total_realisation',
        },
        {
          label: 'Taux',
          field: 'taux_realisation',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      columns2: [
        {
          label: 'Mois',
          field: 'mois',
        },
        {
          label: 'Commercial',
          field: 'commercial',
        },
        {
          label: 'Objectif 1L',
          field: 'objectif_stock_sortie_1l',
        },
        {
          label: 'Objectif 50CL',
          field: 'objectif_stock_sortie_50cl',
        },
        {
          label: 'Total Objectif',
          field: 'total_objectif',
        },
        {
          label: 'Réalisation',
          field: 'total_realisation',
        },
        {
          label: 'Taux',
          field: 'taux_realisation',
        },
      ],
      commerciaux: [],
      distributeurs: [],
      objectif: {
        id: null,
        mois: '',
        commercial: '',
        distributeur: '',
        objectif_stock_sortie_1l: '',
        objectif_stock_sortie_50cl: '',
        moisState: null,
        commercialState: null,
        distributeurState: null,
        objectif_stock_sortie_1lState: null,
        objectif_stock_sortie_50clState: null,
      },
      month: '',
      objectifsDetails: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.getObjectifs()
    this.getCommerciaux()
    this.month = this.getFrenchMonthName()
    this.getObjectsMensuelDetails()
  },
  methods: {
    fetchDistributeurs() {
      if (this.objectif.commercial === '') {
        this.distributeurs = []
        return
      }
      this.$http.get(`${API_URL}distributeurs/?commercial_id=${this.objectif.commercial.uuid}`)
        .then(response => {
          this.distributeurs = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    resetModal() {
      // this.commercial.uuid = null
      // this.commercial.nom = ''
      // this.commercial.nomState = null
      // this.commercial.canal = ''
      // this.commercial.responsable = ''
    },
    getCommerciaux() {
      // eslint-disable-next-line no-empty
      if (this.responsableId) {
      } else {
        // console.log(localStorage.getItem('userData'))
        this.$http.get(`${API_URL}commercials/`)
          .then(response => {
            this.commerciaux = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    getObjectifs() {
      this.$http.get(`${API_URL}objectifs-mensuels/`)
        .then(response => {
          // met dans objectifs les pdv du commercial et qui ne sont pas dans rows
          this.objectifs = response.data
          // this.objectifs = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
        this.$http.delete(`${API_URL}objectifs-mensuels/${id}/`).then(response => {
          console.log(response.data)
          showToast(this, 'danger', 'Suppression', 'Element supprimé')
          this.getObjectifs()
        }).catch(error => {
          console.log(error)
        })
      } else {
        // Annuler la suppression ici
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saveObjectif()
    },
    checkFormValidity() {
      console.log(this.objectif)
      this.objectif.moisState = this.objectif.mois.length > 0
      if (this.objectif.commercial.uuid) {
        this.objectif.commercialState = true
      } else {
        this.objectif.commercialState = false
      }
      if (this.objectif.distributeur.id) {
        this.objectif.distributeurState = true
      } else {
        this.objectif.distributeurState = false
      }
      this.objectif.objectif_stock_sortie_1lState = this.objectif.objectif_stock_sortie_1l.length > 0 || this.objectif.objectif_stock_sortie_1l >= 0
      this.objectif.objectif_stock_sortie_50clState = this.objectif.objectif_stock_sortie_50cl.length > 0 || this.objectif.objectif_stock_sortie_50cl >= 0

      return this.objectif.moisState
        && this.objectif.commercialState
        && this.objectif.distributeurState
        && this.objectif.objectif_stock_sortie_1lState
        && this.objectif.objectif_stock_sortie_50clState
    },
    saveObjectif() {
      if (!this.objectif.id && this.objectif.id == null) {
        this.$http.post(`${API_URL}objectifs-mensuels/`, this.objectif)
          .then(response => {
            this.$nextTick(() => {
              this.$refs['objectif-modal'].toggle('#toggle-btn')
            })
            showToast(this, 'primary', 'Enregistrement', 'Element enregistré')
            this.getObjectifs()
            this.resetModal()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.objectif.objectif_stock_sortie_1l = parseInt(this.objectif.objectif_stock_sortie_1l, 10)
        this.objectif.objectif_stock_sortie_50cl = parseInt(this.objectif.objectif_stock_sortie_50cl, 10)
        this.$http.put(`${API_URL}objectifs-mensuels/${this.objectif.id}/`, this.objectif)
          .then(response => {
            showToast(this, 'info', 'Mise à jour', 'Element mis à jour')
            this.getObjectifs()
            this.resetModal()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    handleUpdate(item) {
      this.objectif.id = item.id
      this.objectif.mois = item.mois
      this.objectif.commercial = item.commercial
      this.objectif.distributeur = item.distributeur
      this.objectif.objectif_stock_sortie_1l = item.objectif_stock_sortie_1l
      this.objectif.objectif_stock_sortie_50cl = item.objectif_stock_sortie_50cl
      this.fetchDistributeurs()
      this.$refs['objectif-modal'].toggle('#toggle-btn')
    },
    getObjectsMensuelDetails() {
      this.$http.get(`${API_URL}objectif-mensuel-details/?month=${this.month}`)
        .then(response => {
          this.objectifsDetails = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getFrenchMonthName() {
      const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre',
      ]

      const date = new Date()
      return monthNames[date.getMonth()]
    },

  },
}
</script>

<style scoped>

</style>
